import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import parse from 'html-react-parser';

class Services extends Component {

    constructor() {
        super();
        this.state = {
            servicesPageData: {
                status: 'fail'
            }
        };
    }

    async componentDidMount() {
        const fetchData = async () => {

            var servicesPageUrl = global.config.api_url + "services-page";
            const servicesPageResult = await axios.post(servicesPageUrl);
            if (servicesPageResult.status === 200) {
                if (typeof servicesPageResult.data.data != "undefined") {
                    var servicesPageResultData = servicesPageResult.data;
                    this.setState({
                        servicesPageData: servicesPageResultData
                    });
                }
            }
        }
        fetchData();
    }

    render() {

        var servicesPageHtml = "";

        if (this.state.servicesPageData.status === 'success') {
            {
                this.state.servicesPageData.data.map((servicesPage) => {
                    console.log(servicesPage);
                    if (this.state.servicesPageData.data.length !== 0 && servicesPage.section_name === 'services' && servicesPage.section_description.section_display === 'yes') {
                        servicesPageHtml = <main id="main">
                            <section id="slider" className="hero p-0 odd featured">
                                <div className="swiper-container no-slider slider-h-75">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide slide-center">
                                            <img src={servicesPage.section_description.image} className="full-image" data-mask="70" alt="Services Page Breadcrumbs" />
                                            <div className="slide-content row text-center">
                                                <div className="col-12 mx-auto inner">
                                                    <h1 className="title effect-static-text">Services</h1>
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                            <li className="breadcrumb-item active" aria-current="page">Services</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        
                            {(servicesPage.section_description.page_description.replace(/(<([^>]+)>)/ig, '') !== "" && servicesPage.section_description.page_description.replace(/(<([^>]+)>)/ig, '') !== null) ? <section id="about" className="section-1 highlights image-right">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            {parse(servicesPage.section_description.page_description)}
                                        </div>
                                    </div>
                                </div>
                            </section> : ""}
                        
                            {servicesPage.section_description.services_section.map((servicesSection, servicesSectionIndex) => {
                                                            return((servicesSectionIndex % 2 === 0) ? <section className="section-3 highlights image-right">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="gallery col-12 col-md-6">
                                                                            <img src={servicesSection.image} className="fit-image" alt={servicesSection.title} />
                                                                        </div>
                                                                        <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                                                                            <h3 className="effect-static-text">{servicesSection.title}</h3>
                                                                            {parse(servicesSection.description)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : <section className="section-1 highlights image-right">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                                                                            <h3 className="effect-static-text">{servicesSection.title}</h3>
                                                                            {parse(servicesSection.description)}
                                                                        </div>
                                                                        <div className="gallery col-12 col-md-6">
                                                                            <img src={servicesSection.image} className="fit-image" alt={servicesSection.title} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>)
                                                        })}
                        </main>;
                    }
                });
            }
        }

        return servicesPageHtml;
    }
}
export default Services