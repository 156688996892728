import React, {Component}
from 'react';
import {Link}
from 'react-router-dom';

class Contact extends Component{
render(){
return(
        <main id="main">
    {/* <!-- Hero --> */}
    <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider slider-h-75">
            <div className="swiper-wrapper">
                {/* <!-- Item 1 --> */}
                <div className="swiper-slide slide-center">
                    <img src="assets/images/thank-you.png" className="full-image" data-mask="70" alt="" />
                    <div className="slide-content row text-center">
                        <div className="col-md-12 mx-auto inner">
                            <h1 className="title effect-static-text">Contact Us</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Contacts --> */}
    <section id="contacts" className="section-1 offers">
        <div className="container">
            <div className="row intro">
                <div className="col-md-12 col-md-9 align-self-center text-center text-md-left">
                    <h2 className="featured">How Can We Help?</h2>
                    <p>Talk to one of our consultants today and learn how to start leveraging your business.</p>
                </div>
                <div className="col-md-12 col-md-3 align-self-end">
                    <Link to="#contact" className="smooth-anchor btn mx-auto mr-md-0 ml-md-auto primary-button"><i className="icon-speech"></i>GET IN TOUCH</Link>
                </div>
            </div>
            <div className="row justify-content-center text-center items contact_card">
                <div className="col-md-12 col-md-6 col-lg-3 item">
                    <div className="card featured">
                        <i className="fa fa-phone"></i>
                        <h4><Link to="tel:09011404003">09011404003</Link></h4>
                        <h4><Link to="tel:064-433004">064-433004</Link></h4>
                        <h4><Link to="tel:064-431551">064-431551</Link></h4>
                    </div>
                </div>
                <div className="col-md-12 col-md-6 col-lg-3 item">
                    <div className="card">
                        <i className="fa fa-envelope-o"></i>
                        <h4><Link to="mailto:gezawacommodityexchange@gmail.com">gezawacommodityexchange@gmail.com</Link></h4>
                        <h4><Link to="mailto:info@gcmx.ng">info@gcmx.ng</Link></h4>
                    </div>
                </div>
                <div className="col-md-12 col-md-6 col-lg-3 item">
                    <div className="card featured">
                        <i className="fa fa-map-signs"></i>
                        <h4>Kano city office</h4>
                        <h4>1-64 Maikifi Street, Gandun Albassa Housing Estate, Off Zoo Road, Kano</h4>
                    </div>
                </div>
                <div className="col-md-12 col-md-6 col-lg-3 item">
                    <div className="card featured">
                        <i className="fa fa-map-signs"></i>
                        <h4>Head Office</h4>
                        <h4>No.1 Gumel Road, Gezawa Town, Gezawa Local Government Kano State, Nigeria</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Contact --> */}
    <section id="contact" className="section-3 form">
        <div className="container">
            <div className="row text-center form-content">
                <div className="col-md-3 p-0"></div>
                <div className="col-md-6 p-0">
                    <h2>Let's Talk?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    <form action="">
                        <div className="col-md-12 p-0">
                            {/* <!-- Group 1 --> */}
                            <fieldset className="step-group" id="step-group-1">
                                <div className="row">
                                    <div className="col-md-12 input-group p-0">
                                        <input type="text" name="name" data-minlength="3" className="form-control field-name invalid" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 input-group p-0">
                                        <input type="email" name="email" data-minlength="3" className="form-control field-email invalid" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 input-group p-0">
                                        <input type="text" name="phone" data-minlength="3" className="form-control field-phone invalid" placeholder="Phone" />
                                    </div>
                                </div>
                                <div className="col-md-12 input-group p-0">
                                    {/* <button className="primary-button contact_btn">SEND &nbsp;<i className="fa fa-angle-double-right"></i></button> */}
                                    <Link to="" className="primary-button contact_btn">SEND &nbsp;<i className="fa fa-angle-double-right"></i></Link>
                                </div>
                            </fieldset>
                        </div>
                    </form>
                </div>
                <div className="col-md-3 p-0"></div>
            </div>
        </div>
    </section>

    {/* <!-- Custom --> */}
    <section id="custom" className="section-2 map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.123073808986!2d12.490042215441486!3d41.89021017922119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61b6532013ad%3A0x28f1c82e908503c4!2sColiseu!5e0!3m2!1spt-BR!2sbr!4v1594148229878!5m2!1spt-BR!2sbr" width="600" height="450" aria-hidden="false" tabIndex="0" title="Map" ></iframe>
    </section>
</main>
        )
}
}
export default Contact