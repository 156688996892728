import React, {useEffect, useState} from "react";
//import ReactDOM from 'react-dom';
import {Link}
from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//import AOS from 'aos';
import '../../node_modules/aos/dist/aos.css';
import axios from "axios";
import { ExternalLink } from 'react-external-link';



function Home() {
    
        const tab_slider = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

    const [homePageData, setHomePage] = useState([]);
    const fetchData = async () => {
        var homePageUrl = global.config.api_url + "home-page";
        const result = await axios.post(homePageUrl);
        if (result.status === 200) {
            if (typeof result.data.data != "undefined") {
                var homePageResultData = result.data.data;
                setHomePage(homePageResultData);
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    
    var Html = <main id="main">
    
    { homePageData.map((homePageSection, index) => {
        
    if (homePageData.length > 0 && homePageSection.section_name === 'section1' && homePageSection.section_description.section_display === 'yes') {
        return (<section id="slider" key={index} className="hero p-0 featured">
        <div className="swiper-container no-slider animation slider-h-100">
            <div className="swiper-wrapper">
                <div className="swiper-slide slide-center">
                    <img src={homePageSection.section_description.image} className="hero-image-left" alt="Section1" />
                    <div className="slide-content row" data-mask-768="70">
                    <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end inner">
                            <div className="left text-center text-md-left col-11">
                            <h1 className="title effect-static-text " >{homePageSection.section_description.section_title}</h1>
                                <div className="d-sm-inline-flex mt-3">
                                {(homePageSection.section_description.button_link!=="" && homePageSection.section_description.button_link !== null)?<ExternalLink href={homePageSection.section_description.button_link} target="_blank" className="ml-auto mr-auto mr-md-0 mt-4 mt-sm-0 btn primary-button"><i className="icon-cup"></i>{homePageSection.section_description.button}</ExternalLink>:""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="swiper-pagination"></div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section2' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="main_slogen" key={index} className="section-5 showcase">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <h3 className="effect-static-text">{homePageSection.section_description.section_sub_title}</h3>
                        <h5>{homePageSection.section_description.section_description}</h5>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section3' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="services" key={index} className="section-1 offers">
        <div className="container">
            <div className="row intro">
                <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                    <h2 className="featured">{homePageSection.section_description.section_title}</h2>
                    <p>{homePageSection.section_description.section_description}</p>
                </div>
                <div className="col-12 col-md-3 align-self-end">
                {(homePageSection.section_description.button_link !== "" && homePageSection.section_description.button_link !== null)?<ExternalLink href={homePageSection.section_description.button_link} target="_blank" className="ml-auto mr-auto mr-md-0 mt-4 mt-sm-0 btn primary-button"><i className="icon-cup"></i>{homePageSection.section_description.button}</ExternalLink>:""}
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section4' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="tranding_commodity" key={index} className="section- offers">
        <div className="container">
            <div className="row text-center">
                <h3 className="effect-static-text pb-5">{homePageSection.section_description.section_title}</h3>
                <div className="row justify-content-center text-center items">
                    <div className="col-12 col-md-6 col-lg-4 item ser_img">
                        <div className="card featured">
                            <h4>{homePageSection.section_description.section_box1_title}</h4>
                            <p>{homePageSection.section_description.section_box1_description}</p>
                            <img src={homePageSection.section_description.section_box1_image} alt={homePageSection.section_description.section_box1_title} />
                        </div> 
                    </div> 
                    <div className="col-12 col-md-6 col-lg-4 item ser_img">
                        <div className="card featured">
                            <h4>{homePageSection.section_description.section_box2_title}</h4>
                            <p>{homePageSection.section_description.section_box2_description}</p>
                            <img src={homePageSection.section_description.section_box2_image} alt={homePageSection.section_description.section_box2_title} />
                        </div> 
                    </div> 
                    <div className="col-12 col-md-6 col-lg-4 item ser_img">
                        <div className="card featured">
                            <h4>{homePageSection.section_description.section_box3_title}</h4>
                            <p>{homePageSection.section_description.section_box3_description}</p>
                            <img src={homePageSection.section_description.section_box3_image} alt={homePageSection.section_description.section_box3_title} />
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section5' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="about" key={index} className="section-2 highlights image-right">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 align-self-center text-center text-md-left intro">
                    <h2 className="featured alt">{homePageSection.section_description.section_title}</h2>
                    <div className="mt-4 mb-4" dangerouslySetInnerHTML={{__html: homePageSection.section_description.section_description}} />
                    <Link to="/about" className="btn primary-button">{(homePageSection.section_description.button!=="" && homePageSection.section_description.button !== null)? homePageSection.section_description.button :"Read More"} &nbsp;<i className="fa fa-angle-double-right"></i></Link>
                </div>
                <div className="col-12 col-md-6">
                    <img src={homePageSection.section_description.image} className="fit-image" alt={homePageSection.section_description.section_title} />
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section6' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="our_promise" key={index} className="section-3 highlights image-right">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <img src={homePageSection.section_description.image} className="fit-image" alt={homePageSection.section_description.section_title} />
                </div>
                <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                    <div className="row">
                        <div className="col-12 pb-2">
                            <h2 className="featured alt">{homePageSection.section_description.section_title}</h2>
                        </div>
                    </div>
                    <div className="row items">
                        <div className="col-12 p-0">
                            <div className="row item">
                                <div className="col-12 col-md-2 align-self-center">
                                    <img src={homePageSection.section_description.section_box1_image} alt={homePageSection.section_description.section_box1_title} />
                                </div>
                                <div className="col-12 col-md-9 align-self-center">
                                    <h4>{homePageSection.section_description.section_box1_title}</h4>
                                    <div className="mt-4" dangerouslySetInnerHTML={{__html: homePageSection.section_description.section_box1_description}} />
                                </div>
                            </div>
                            <div className="row item">
                                <div className="col-12 col-md-2 align-self-center">
                                    <img src={homePageSection.section_description.section_box2_image} alt={homePageSection.section_description.section_box2_title} />
                                </div>
                                <div className="col-12 col-md-9 align-self-center">
                                    <h4>{homePageSection.section_description.section_box2_title}</h4>
                                    <div className="mt-4" dangerouslySetInnerHTML={{__html: homePageSection.section_description.section_box2_description}} />
                                </div>
                            </div>
                            <div className="row item">
                                <div className="col-12 col-md-2 align-self-center">
                                    <img src={homePageSection.section_description.section_box3_image} alt={homePageSection.section_description.section_box3_title} />
                                </div>
                                <div className="col-12 col-md-9 align-self-center">
                                    <h4>{homePageSection.section_description.section_box3_title}</h4>
                                    <div className="mt-4" dangerouslySetInnerHTML={{__html: homePageSection.section_description.section_box3_description}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section7' && homePageSection.section_description.section_display === 'yes') {
        
    return (<section id="carousel" key={index} className="section-4 carousel showcase">
        <div className="overflow-holder">
            <div className="container">
                <div className="row text-center intro">
                    <div className="col-12">
                        <h3 className="effect-static-text">{homePageSection.section_description.section_title}</h3>
                    </div>
                </div>
                <div className="items">
                    <Slider {...tab_slider}>
                        {homePageSection.section_description.section_carousel.map((tab_panel_data,tab_panel_data_index) =>
                                <div key={tab_panel_data_index} className="item">
                                    <div className="row card p-0 text-center">
                                        <div className="gallery">
                                            <Link to={tab_panel_data.image} className="image-over">
                                            <img src={tab_panel_data.image} alt={tab_panel_data.title} />
                                            </Link>
                                        </div>
                                        <div className="card-caption col-12 p-0">
                                            <div className="card-body">
                                                <h4 className="m-0">{tab_panel_data.title}</h4>
                                            </div>
                                            <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                                <div className="" dangerouslySetInnerHTML={{__html: tab_panel_data.description}} />
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            )}
                    </Slider>
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section8' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="building_section" key={index} className="section-2 highlights image-right">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-6">
                    <img src={homePageSection.section_description.image} className="fit-image custom_height" alt="Fit" />
                </div>
                <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                    <div className="row intro">
                        <div className="col-12 p-0">
                            <h4 className="featured alt">{homePageSection.section_description.section_title}</h4>
                            <div id="funfacts" className="odd counter funfacts">
                                <div data-aos-id="counter" data-aos="fade-up" data-aos-delay="200" className="row justify-content-center text-center items aos-init aos-animate">
                                    <div className="col-12 col-md-6 col-lg-6 item">
                                        <div data-percent={homePageSection.section_description.counter_number_1} className="radial"><span>{homePageSection.section_description.counter_number_1}</span></div>
                                        <h4>{homePageSection.section_description.counter_text_1}</h4>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 item">
                                        <div data-percent={homePageSection.section_description.counter_number_2} className="radial"><span>{homePageSection.section_description.counter_number_2}<i>MT</i></span></div>
                                        <h4>{homePageSection.section_description.counter_text_2}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
    }
    
    if (homePageData.length > 0 && homePageSection.section_name === 'section9' && homePageSection.section_description.section_display === 'yes') {
    return (<section id="update_table" key={index} className="section-1 offers">
        <div className="container">
            <div className="row intro">
                <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                    <h3 className="featured">{homePageSection.section_description.section_title}</h3>
                </div>
                <div className="col-12 col-md-3 align-self-end">
                    {(homePageSection.section_description.button_link!=="" && homePageSection.section_description.button_link !== null)?<ExternalLink href={homePageSection.section_description.button_link} target="_blank" className="ml-auto mr-auto mr-md-0 mt-4 mt-sm-0 btn primary-button">{homePageSection.section_description.button} <i className="fa fa-angle-double-right"></i> </ExternalLink>:""}
                </div>
            </div>

            <div className="row justify-content-center text-center items">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>COMMODITY</th>
                                <th>PRICE(NGN)	</th>
                                <th>CHANGE(%)</th>
                                <th>CHANGE(NGN)</th>
                            </tr>
                        </thead>
                        <tbody>
                        {homePageSection.section_description.section_commodity_data.map((commodity_data,commodity_data_index) =>
                                <tr key={commodity_data_index} >
                                    <td>{commodity_data.commodity}</td>
                                    <td>{commodity_data.price}</td>
                                    <td>{commodity_data.change_in_percentage}</td>
                                    <td>{commodity_data.change_in_amount}</td>
                                </tr>
                    )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>);
    }
    return true;
    })
    }
    
</main>
    return(Html);
}

export default Home