import React, {Component}
from 'react';
import {Link}
from 'react-router-dom';
import Marquee from "react-fast-marquee";
import NumberFormat from "react-number-format";
import axios from "axios";
import { ExternalLink } from 'react-external-link';

class Footer extends Component {

    constructor() {
        super();
        this.state = {
            liveMarketPrices: {
                status: 'fail'
            }, footerData: {
                status: 'fail'
            }
        };
    }

    async componentDidMount() {
        const fetchData = async () => {

            var liveMarketPricesUrl = global.config.api_url + "get-live-market-prices";
            var footerUrl = global.config.api_url + "get-footer";

            const liveMarketPricesResult = await axios.post(liveMarketPricesUrl);
            if (liveMarketPricesResult.status === 200) {
                if (typeof liveMarketPricesResult.data.data != "undefined") {
                    var liveMarketPricesResultData = liveMarketPricesResult.data;
                    this.setState({
                        liveMarketPrices: liveMarketPricesResultData
                    });
                }
            }

            const footerResult = await axios.post(footerUrl);
            if (footerResult.status === 200) {
                if (typeof footerResult.data.data != "undefined") {
                    var footerResultData = footerResult.data;
                    this.setState({
                        footerData: footerResultData
                    });
                }
            }
        }
        fetchData();
    }

    render() {

        var liveMarketPricesHtml = "";
        var footerSectionHtml = "";

        if (this.state.liveMarketPrices.status === 'success') {

            if (this.state.liveMarketPrices.data.length !== 0) {
                liveMarketPricesHtml = <section id="commodity_live_section">
                    <div className="tz_fix_sec">
                        <div className="tz_live_sec_title">
                            <p>LIVE MARKET PRICES</p>
                        </div>
                        <div className="live_market_price">
                            <Marquee delay="0" speed="35" gradient="true" gradientWidth="0" direction="left">
                                <ul className="live_price">
                                    {this.state.liveMarketPrices.data.map((element) => {
                                                            return <li key={element.id} >
                                                                <h4>{element.commodity}</h4>
                                                                <div className="price_change_sec">
                                                                    <span>
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="lakh"
                                                                            value={element.price}
                                                                            prefix="N"
                                                                            decimalSeparator="."
                                                                            displayType="text"
                                                                            type="text"
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={0} />
                                                                    </span>
                                                                    <span className="change_price">{element.percentage}%  {element.status === 1 ? <i className="fa fa-angle-double-right"></i> : (element.status === 2 ? <i className="fa fa-angle-double-up"></i> : (element.status === 3 ? <i className="fa fa-angle-double-down"></i> : ""))}
                                                                    </span>
                                                                </div>
                                                            </li>;
                                                        })}
                                </ul>
                            </Marquee>
                        </div>
                    </div>
                </section>;
            }
        }

        if (this.state.footerData.status === 'success') {
            { this.state.footerData.data.map((footerSection) => {
                    
                if (this.state.footerData.data.length !== 0 && footerSection.section_name === 'footer' && footerSection.section_description.section_display === 'yes') {
                    
                    footerSectionHtml = <div>
                                            <section id="footer" className="footer">
                                                <div className="container">
                                                    <div className="row items footer-widget">
                                                        <div className="col-12 col-lg-3 p-0">
                                                            <div className="row">
                                                                <div className="branding col-12 p-3 text-center text-lg-left item">
                                                                    <div className="brand">
                                                                        <Link to="/" className="logo">{footerSection.section_description.section_title}</Link>
                                                                    </div>
                                                                    <div className="mt-4 mb-4" dangerouslySetInnerHTML={{__html: footerSection.section_description.page_description}} />
                                                                    <ul className="navbar-nav social share-list mt-0 ml-auto">

                                                                    {(footerSection.section_description.instagram_link!=="" && footerSection.section_description.instagram_link !== null)?
                                                                    <li className="nav-item">
                                                                        <ExternalLink href={footerSection.section_description.instagram_link} className="nav-link"><i className="fa fa-instagram"></i></ExternalLink>
                                                                    </li>
                                                                    :""}

                                                                    {(footerSection.section_description.facebook_link!=="" && footerSection.section_description.facebook_link !== null)?
                                                                    <li className="nav-item">
                                                                        <ExternalLink href={footerSection.section_description.facebook_link} className="nav-link"><i className="fa fa-facebook"></i></ExternalLink>
                                                                    </li>
                                                                    :""}

                                                                    {(footerSection.section_description.linkedin_link!=="" && footerSection.section_description.linkedin_link !== null)?
                                                                    <li className="nav-item">
                                                                        <ExternalLink href={footerSection.section_description.linkedin_link} className="nav-link"><i className="fa fa-linkedin"></i></ExternalLink>
                                                                    </li>
                                                                    :""}

                                                                    {(footerSection.section_description.twitter_link!=="" && footerSection.section_description.twitter_link !== null)?
                                                                    <li className="nav-item">
                                                                        <ExternalLink href={footerSection.section_description.twitter_link} className="nav-link"><i className="fa fa-twitter"></i></ExternalLink>
                                                                    </li>
                                                                    :""}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-9 p-0">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                                                    <h4 className="title">Quick Links</h4>
                                                                    <ul className="navbar-nav">
                                                                        <li className="nav-item">
                                                                            <Link to="/" className="nav-link">Home</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/about" className="nav-link">About Us</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/services" className="nav-link">Products / Services</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/market" className="nav-link">Market</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/faqs" className="nav-link">Faq</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <Link to="/contact" className="nav-link">Contact Us</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                                                    <h4 className="title">Our Services</h4>
                                                                    <ul className="navbar-nav">

                                                                        {(footerSection.section_description.services_link_1!=="" && footerSection.section_description.services_link_1 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_1} target="_self" className="nav-link">{footerSection.section_description.services_title_1}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.services_link_2!=="" && footerSection.section_description.services_link_2 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_2} target="_self" className="nav-link">{footerSection.section_description.services_title_2}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.services_link_3!=="" && footerSection.section_description.services_link_3 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_3} target="_self" className="nav-link">{footerSection.section_description.services_title_3}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.services_link_4!=="" && footerSection.section_description.services_link_4 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_4} target="_self" className="nav-link">{footerSection.section_description.services_title_4}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.services_link_5!=="" && footerSection.section_description.services_link_5 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_5} target="_self" className="nav-link">{footerSection.section_description.services_title_5}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.services_link_6!=="" && footerSection.section_description.services_link_6 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={footerSection.section_description.services_link_6} target="_self" className="nav-link">{footerSection.section_description.services_title_6}</ExternalLink>
                                                                        </li>
                                                                        :""}
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item contact_list">
                                                                    <h4 className="title">Contact Us</h4>
                                                                    <h6>Telephone :-</h6>
                                                                    <ul className="navbar-nav">

                                                                        {(footerSection.section_description.phone_number_1!=="" && footerSection.section_description.phone_number_1 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={'tel:'+footerSection.section_description.phone_number_1} target="_self" className="nav-link"><i className="fa fa-phone"></i>&nbsp;&nbsp; {footerSection.section_description.phone_number_1}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.phone_number_2!=="" && footerSection.section_description.phone_number_2 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={'tel:'+footerSection.section_description.phone_number_2} target="_self" className="nav-link"><i className="fa fa-phone"></i>&nbsp;&nbsp; {footerSection.section_description.phone_number_2}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.phone_number_3!=="" && footerSection.section_description.phone_number_3 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={'tel:'+footerSection.section_description.phone_number_3} target="_self" className="nav-link"><i className="fa fa-phone"></i>&nbsp;&nbsp; {footerSection.section_description.phone_number_3}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                    </ul>

                                                                    <h6>Email :-</h6>
                                                                    <ul className="navbar-nav">

                                                                        {(footerSection.section_description.email_address_1!=="" && footerSection.section_description.email_address_1 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={'mailto:'+footerSection.section_description.email_address_1} target="_self" className="nav-link"><i className="fa fa-envelope-o"></i>&nbsp;&nbsp; {footerSection.section_description.email_address_1}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                        {(footerSection.section_description.email_address_2!=="" && footerSection.section_description.email_address_2 !== null)?
                                                                        <li className="nav-item">
                                                                            <ExternalLink href={'mailto:'+footerSection.section_description.email_address_2} target="_self" className="nav-link"><i className="fa fa-envelope-o"></i>&nbsp;&nbsp; {footerSection.section_description.email_address_2}</ExternalLink>
                                                                        </li>
                                                                        :""}

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="copyright" className="p-3 copyright">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 text-center text-lg-center">
                                                            <p>© 2021 Gezawa Commodity Exchange.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>;
                }
                    
                    return true;
            })}
        }

        return (<footer>
                {footerSectionHtml}
                {liveMarketPricesHtml}
            </footer>);
    }
}
export default Footer