import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";

class Faqs extends Component {

    constructor() {
        super();
        this.state = {
            faqPageData: {
                status: 'fail'
            }
        };
    }

    async componentDidMount() {
        const fetchData = async () => {

            var faqPageUrl = global.config.api_url + "get-faqs";

            const faqPageResult = await axios.post(faqPageUrl);
            if (faqPageResult.status === 200) {
                if (typeof faqPageResult.data.data != "undefined") {
                    var faqPageResultData = faqPageResult.data;
                    this.setState({
                        faqPageData: faqPageResultData
                    });
                }
            }
        }
        fetchData();

    }

    render() {

        var faqHtml = "";
        if (this.state.faqPageData.status === 'success') {
            {
                faqHtml = this.state.faqPageData.data.map((faq) => {
                    if (this.state.faqPageData.data.length !== 0) {
                        return(<div className="accordian_card" key={faq.id}>
                            <div className="card-header">
                                <p className="mb-0">
                                    <button className="btn btn-link accordian_btn" type="button" data-toggle="collapse" data-target={'#faq' + faq.id} aria-expanded="true" aria-controls={'#faq' + faq.id}>{faq.question} &nbsp;<i className="fa fa-angle-right custom_accordian_icon"></i></button>
                                </p>
                            </div>
                            <div id={'faq' + faq.id} className="collapse" data-parent="#accordionExample">
                                <div className="card-body" dangerouslySetInnerHTML={{__html: faq.answer}} />
                            </div>
                        </div>)
                    }

                })
            }
        }

        return (
                <main id="main">
                    <section id="slider" className="hero p-0 odd featured">
                        <div className="swiper-container no-slider slider-h-75">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide slide-center">
                                    <img src="assets/images/faq_bg.png" className="full-image" data-mask="70" alt="" />
                                    <div className="slide-content row text-center">
                                        <div className="col-12 mx-auto inner">
                                            <h1 className="title effect-static-text">FAQ's</h1>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">FAQ's</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                
                    <section id="contacts" className="section-1 offers">
                        <div className="container">
                            <div className="accordion" id="accordionExample">
                                {faqHtml}
                            </div>
                        </div>
                    </section>
                </main>
                )
    }
}
export default Faqs