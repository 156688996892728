import React, { useEffect, useState}
from "react";
//import ReactDOM from 'react-dom';
import {Link}
from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//import AOS from 'aos';
import '../../node_modules/aos/dist/aos.css';
import axios from "axios";
import { ExternalLink } from 'react-external-link';
import { Bar } from 'react-chartjs-2';
import parse from 'html-react-parser';

function Market() {

    const [marketPageData, setMarketPage] = useState([]);
    const fetchData = async () => {
        var marketPageUrl = global.config.api_url + "market-page";
        const result = await axios.post(marketPageUrl);
        if (result.status === 200) {
            if (typeof result.data.data != "undefined") {
                var marketPageResultData = result.data.data;
                setMarketPage(marketPageResultData);
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    var Html = <main id="main">
    
        { marketPageData.map((homePageSection, index) => {
                    if (marketPageData.length > 0 && homePageSection.section_name === 'section1' && homePageSection.section_description.section_display === 'yes') {
                        return(<div key={index} >
                            <section id="slider"  className="hero p-0 odd featured">
                                <div className="swiper-container no-slider slider-h-75">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide slide-center">
                                            <img src={homePageSection.section_description.image} className="full-image" data-mask="70" alt="" />
                                            <div className="slide-content row text-center">
                                                <div className="col-12 mx-auto inner">
                                                    <h1 className="title effect-static-text">Market</h1>
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                            <li className="breadcrumb-item active" aria-current="page">Market</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="about" className="section-3 highlights image-right">
                                <div className="container">
                                    <div className="row">
                                        <div className="gallery col-12 col-md-6">
                                            <img src={homePageSection.section_description.section_image} className="fit-image" alt="Market" />
                                        </div>
                                        <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                                            <h3 className="effect-static-text">{homePageSection.section_description.section_title}</h3>
                                            {parse(homePageSection.section_description.page_description)}
                                            {(homePageSection.section_description.button_link !== "" && homePageSection.section_description.button_link !== null) ? <ExternalLink href={homePageSection.section_description.button_link} target="_blank" className="btn primary-button">{homePageSection.section_description.button} &nbsp;<i className="fa fa-angle-double-right"></i></ExternalLink> : ""}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>);
                    }

                    if (marketPageData.length > 0 && homePageSection.section_name === 'section2' && homePageSection.section_description.section_display === 'yes') {
                        const data = {
                            labels: ['Maize', 'Soybeans', 'Paddy Rice', 'Sorghum', 'Ginger', 'Cocoa', 'Sesame', 'Cashew'],
                            datasets: [{
                                    label: '2020',
                                    data: [homePageSection.section_description.maize2, homePageSection.section_description.soybeans2, homePageSection.section_description.paddy_rice2, homePageSection.section_description.sorghum2, homePageSection.section_description.ginger2, homePageSection.section_description.cocoa2, homePageSection.section_description.sesame2, homePageSection.section_description.cashew2],
                                    options: {
                                        responsive: true,
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "Poppins"
                                                }
                                            }
                                        }
                                    }, backgroundColor: [
                                        'rgba(255, 99, 132, 0.2)',
                                        'rgba(255, 65, 64, 0.2)',
                                        'rgba(255, 86, 86, 0.2)',
                                        'rgba(75, 92, 192, 0.2)',
                                        'rgba(54, 48, 235, 0.2)',
                                        'rgba(153, 62, 255, 0.2)',
                                        'rgba(201, 68, 207, 0.2)'
                                    ], borderColor: [
                                        'rgb(255, 99, 132)',
                                        'rgb(255, 65, 64)',
                                        'rgb(255, 86, 86)',
                                        'rgb(75, 92, 192)',
                                        'rgb(54, 48, 235)',
                                        'rgb(153, 62, 255)',
                                        'rgb(201, 68, 207)'
                                    ], borderWidth: 1
                                }, {
                                    label: '2021',
                                    data: [homePageSection.section_description.maize1, homePageSection.section_description.soybeans1, homePageSection.section_description.paddy_rice1, homePageSection.section_description.sorghum1, homePageSection.section_description.ginger1, homePageSection.section_description.cocoa1, homePageSection.section_description.sesame1, homePageSection.section_description.cashew1],
                                    options: {
                                        responsive: true,
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "Poppins"
                                                }
                                            }
                                        }
                                    }, backgroundColor: [
                                        'rgba(255, 99, 132, 0.2)',
                                        'rgba(255, 65, 64, 0.2)',
                                        'rgba(255, 86, 86, 0.2)',
                                        'rgba(75, 92, 192, 0.2)',
                                        'rgba(54, 48, 235, 0.2)',
                                        'rgba(153, 62, 255, 0.2)',
                                        'rgba(201, 68, 207, 0.2)'
                                    ], borderColor: [
                                        'rgb(255, 99, 132)',
                                        'rgb(255, 65, 64)',
                                        'rgb(255, 86, 86)',
                                        'rgb(75, 92, 192)',
                                        'rgb(54, 48, 235)',
                                        'rgb(153, 62, 255)',
                                        'rgb(201, 68, 207)'
                                    ], borderWidth: 1
                                }]
                        };

                        return(<div key={index} >
                            <section id="graph" className="section-1 highlights image-right">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-2"></div>
                                        <div className="col-12 col-md-8 text-center">
                                            <h3 className="effect-static-text">{homePageSection.section_description.section_title}</h3>
                                            <p className="pb-3">{homePageSection.section_description.section_short_text}</p>
                                            <Bar data={data}></Bar>
                                        </div>
                                        <div className="col-12 col-md-2"></div>
                                    </div>
                                </div>
                            </section>
                        </div>);
                    }

                    if (marketPageData.length > 0 && homePageSection.section_name === 'section3' && homePageSection.section_description.section_display === 'yes') {

                        return(<div key={index} >
                            <section id="tranding_commodity" className="section- offers">
                                <div className="container">
                                    <div className="row text-center">
                                        <h3 className="effect-static-text">{homePageSection.section_description.section_title}</h3>
                                        <p className="pb-4 w-50">{homePageSection.section_description.section_short_text}</p>
                                        <div className="row justify-content-center text-center items">
                                            <div className="col-12 col-md-6 col-lg-4 item ser_img">
                                                <div className="card featured">
                                                    <h4>{homePageSection.section_description.section_box1_title}</h4>
                                                    <p className="pb-1">{homePageSection.section_description.section_box1_description}</p>
                                                    {(homePageSection.section_description.section_box1_button_link !== "" && homePageSection.section_description.section_box1_button_link !== null) ? <ExternalLink href={homePageSection.section_description.section_box1_button_link} target="_blank" className="team_mem_link pb-3">{homePageSection.section_description.section_box1_button_text} &nbsp;<i className="fa fa-angle-double-right"></i></ExternalLink> : ""}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 item ser_img">
                                                <div className="card featured">
                                                    <h4>{homePageSection.section_description.section_box2_title}</h4>
                                                    <p className="pb-1">{homePageSection.section_description.section_box2_description}</p>
                                                    {(homePageSection.section_description.section_box2_button_link !== "" && homePageSection.section_description.section_box2_button_link !== null) ? <ExternalLink href={homePageSection.section_description.section_box2_button_link} target="_blank" className="team_mem_link pb-3">{homePageSection.section_description.section_box2_button_text} &nbsp;<i className="fa fa-angle-double-right"></i></ExternalLink> : ""}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 item ser_img">
                                                <div className="card featured">
                                                    <h4>{homePageSection.section_description.section_box3_title}</h4>
                                                    <p className="pb-1">{homePageSection.section_description.section_box3_description}</p>
                                                    {(homePageSection.section_description.section_box3_button_link !== "" && homePageSection.section_description.section_box3_button_link !== null) ? <ExternalLink href={homePageSection.section_description.section_box3_button_link} target="_blank" className="team_mem_link pb-3">{homePageSection.section_description.section_box2_button_text} &nbsp;<i className="fa fa-angle-double-right"></i></ExternalLink> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>);
                    }

                    if (marketPageData.length > 0 && homePageSection.section_name === 'section4' && homePageSection.section_description.section_display === 'yes') {
                        console.log(homePageSection);
                        return(<div key={index} >
                            <section id="core_value" className="section-4 highlights image-right">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12" >
                                            <h3 className="effect-static-text">{homePageSection.section_description.section_title}</h3>
                                            <p>{homePageSection.section_description.section_short_text}</p>
                                            <div className="row">
                                                {homePageSection.section_description.weekly_report.map((weekly_report, weeklyReportIndex) => {
                                                                            return(<div key={weeklyReportIndex} className="gallery col-12 col-md-3 pr-md-5 pl-md-5" >
                                                                                <div className="custom_icon_box text-center">
                                                                                    <img src="assets/images/pdf.png" alt="" />
                                                                                    <h4>{weekly_report.commodity}</h4>
                                                                                    {(weekly_report.weekly_report !== "" && weekly_report.weekly_report !== null) ? <ExternalLink href={weekly_report.weekly_report} target="_blank" className="ml-auto mr-auto mr-md-0 mt-4 mt-sm-0 btn primary-button">Download &nbsp;<i className="fa fa-download"></i></ExternalLink> : ""}
                                                                                </div>
                                                                            </div>)
                                                                        })}
                    
                                            </div>                    
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>);
                    }

                    return true;
                })
        }
    </main>

    return(Html);
}

export default Market