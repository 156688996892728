import {Link} from 'react-router-dom';
        function Header() {
        return (<header id="header">
    <nav data-aos="zoom-out" data-aos-delay="800" className="navbar navbar-expand">
        <div className="container header">
            <Link to="/" className="navbar-brand">
            <img src="assets/images/main_logo-t.png" alt="Logo" />
            </Link>

            <div className="ml-auto"></div>

            <ul className="navbar-nav items">
                <li className="nav-item">
                    <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                    <Link to="/about" className="nav-link">About Us</Link>
                </li>
                <li className="nav-item">
                    <Link to="/services" className="nav-link">Products / Services</Link>
                </li>
                <li className="nav-item">
                    <Link to="/market" className="nav-link">Market</Link>
                </li>
                <li className="nav-item">
                    <Link to="/events" className="nav-link">Events</Link>
                </li>
                <li className="nav-item">
                    <Link to="/gallery" className="nav-link">Gallery</Link>
                </li>
                <li className="nav-item">
                    <Link to="/faqs" className="nav-link">Faq</Link>
                </li>
                <li className="nav-item">
                    <Link to="/contact" className="nav-link">Contact Us</Link>
                </li>
            </ul>

            {/* <!-- Navbar Toggle --> */}
            <ul className="navbar-nav toggle">
                <li className="nav-item">
                    <Link to="/" className="nav-link" data-toggle="modal" data-target="#menu">
                    <i className="fa fa-bars"></i>
                    </Link>
                </li>
            </ul>

            <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                    <Link to="/investment" className="smooth-anchor btn ml-lg-auto primary-button">Investments</Link>
                </li>
            </ul>
        </div>
    </nav>

    {/* <!-- Modal [responsive menu] --> */}
    <div id="menu" className="p-0 modal fade" role="dialog" aria-labelledby="menu" aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout" role="document">
            <div className="modal-content full">
                <div className="modal-header" data-dismiss="modal">Menu <i className="fa fa-times"></i></div>
                <div className="menu modal-body">
                    <div className="row w-100">
                        <div className="items p-0 col-12 text-center"></div>
                        <div className="contacts p-0 col-12 text-center"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
                );
        }
export default Header