import React, {Component}
from 'react';
import {Link}
from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import parse from 'html-react-parser';

class About extends Component {

    constructor() {
        super();
        this.state = {
            aboutPageData: {
                status: 'fail'
            }
        };
    }

    async componentDidMount() {
        const fetchData = async () => {

            var aboutUsPageUrl = global.config.api_url + "about-us-page";
            console.log(aboutUsPageUrl);
            const aboutPageResult = await axios.post(aboutUsPageUrl);
            if (aboutPageResult.status === 200) {
                if (typeof aboutPageResult.data.data != "undefined") {
                    var aboutPageResultData = aboutPageResult.data;
                    this.setState({
                        aboutPageData: aboutPageResultData
                    });
                }
            }
        }
        fetchData();
    }

    render() {
        const team_slider = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        var aboutPageHtml = "";

        if (this.state.aboutPageData.status === 'success') {
            {
                this.state.aboutPageData.data.map((aboutPage) => {
                    
                    if (this.state.aboutPageData.data.length !== 0 && aboutPage.section_name === 'about-us' && aboutPage.section_description.section_display === 'yes') {
                        aboutPageHtml = <main id="main">
                            <section id="slider" className="hero p-0 odd featured">
                                <div className="swiper-container no-slider slider-h-75">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide slide-center">
                                            <img src={aboutPage.section_description.image} className="full-image" data-mask="70" alt="About Page Breadcrumbs" />
                                            <div className="slide-content row text-center">
                                                <div className="col-12 mx-auto inner">
                                                    <h1 className="title effect-static-text">About Us</h1>
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="about" className="section-1 highlights image-right">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-12" dangerouslySetInnerHTML={{__html: aboutPage.section_description.page_description}} />
                                    </div>
                                </div>
                            </section>
                            {aboutPage.section_description.about_section.map((aboutPageSection, aboutPageSectionIndex) => {
                                                            return(<section id="about" className="section-3 highlights image-right commen_m_v">
                                                                <div className="container">
                                                                    {(aboutPageSectionIndex % 2 === 0) ?
                                                                                                        <div className="row">
                                                                                                            <div className="gallery col-12 col-md-6">
                                                                                                                <img src={aboutPageSection.image} className="fit-image" alt="About Us" />
                                                                                                            </div>
                                                                                                            <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                                                                                                                <h2 className="featured alt">{aboutPageSection.title}</h2>
                                                                                                                {parse(aboutPageSection.description)}
                                                                                                            </div>
                                                                                                        </div> :
                                                                                                        <div className="row">
                                                                                                            <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                                                                                                                <h2 className="featured alt">{aboutPageSection.title}</h2>
                                                                                                                {parse(aboutPageSection.description)}
                                                                                                            </div>
                                                                                                            <div className="gallery col-12 col-md-6">
                                                                                                                <img src="assets/images/img_11.jpg" className="fit-image" alt="About Us" />
                                                                                                            </div>
                                                                                                        </div>}
                                                                </div>
                                                            </section>);
                                                        })}
                        
                            <section id="team" className="section-2 carousel">
                                <div className="overflow-holder">
                                    <div className="container">
                                        <div className="row text-center intro">
                                            <div className="col-12">
                                                <h2 className="mb-0 super effect-static-text">{aboutPage.section_description.about_page_team_members_title}</h2>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <Slider {...team_slider}>
                                                {aboutPage.section_description.teamMember.map((aboutPageTeamMember) => {
                                                                                return(<div className="row card text-center" key={aboutPageTeamMember.id}>
                                                                                    <div className="col-12">
                                                                                        <img src={aboutPageTeamMember.image} alt={aboutPageTeamMember.name} className="person" />
                                                                                        <h4>{aboutPageTeamMember.name}</h4>
                                                                                        <h6>{aboutPageTeamMember.designation}</h6>
                                                                                        {parse(aboutPageTeamMember.description)}
                                                                                    </div>
                                                                                </div>);
                                                                            })}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        
                        </main>
                    }
                }
                )
            }
        }

        return aboutPageHtml;
    }

}
export default About