import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Faqs from './pages/FAQs';
import Services from './pages/Services';
import Market from './pages/Market';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import EventSingle from './pages/EventSingle';
import Investment from './pages/Investment';

const getBasename = path => path.substr(0, path.lastIndexOf('/'));
function App() {
  return (
    <div>
      <Router basename={getBasename(window.location.pathname)}>
        <Header/>
          <ScrollToTop />
          <Switch>
            <Route exact path='/'component={Home}/>
            <Route path='/about' component={About}/>
            <Route path='/contact' component={Contact}/>
            <Route path='/faqs' component={Faqs}/>
            <Route path='/services' component={Services}/>
            <Route path='/market' component={Market}/>
            <Route path='/gallery' component={Gallery}/>
            <Route path='/events' component={Events}/>
            <Route path='/events_detail' component={EventSingle}/>
            <Route path='/investment' component={Investment}/>
          </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
