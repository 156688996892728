import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import axios from "axios";

class Gallery extends Component {

    constructor() {
        super();
        this.state = {
            galleryPageData: {
                status: 'fail'
            }
        };
    }

    async componentDidMount() {
        const fetchData = async () => {

            var galleryPageUrl = global.config.api_url + "gallery-page";

            const galleryPageResult = await axios.post(galleryPageUrl);
            if (galleryPageResult.status === 200) {
                if (typeof galleryPageResult.data.data != "undefined") {
                    var galleryPageResultData = galleryPageResult.data;
                    this.setState({
                        galleryPageData: galleryPageResultData
                    });
                }
            }
        }
        fetchData();

    }

    render() {
        var galleryPageHtml = "";
        if (this.state.galleryPageData.status === 'success') {
            {
                this.state.galleryPageData.data.map((galleryPage) => {

                    if (this.state.galleryPageData.data.length !== 0 && galleryPage.section_name === 'gallery_section' && galleryPage.section_description.section_display === 'yes') {
                        galleryPageHtml = <main id="main">
                            <section id="slider" className="hero p-0 odd featured">
                                <div className="swiper-container no-slider slider-h-75">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide slide-center">
                                            <img src={galleryPage.section_description.image} className="full-image" data-mask="70" alt="Gallery" />
                                            <div className="slide-content row text-center">
                                                <div className="col-12 mx-auto inner">
                                                    <h1 className="title effect-static-text">Gallery</h1>
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                            <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="gallery" className="section-1 highlights">
                                <div className="container">
                                    <SimpleReactLightbox>
                                        <SRLWrapper>
                                            <div className="row gallery_img">
                                            {galleryPage.section_description.images.map((galleryImage, galleryImageIndex) => {
                                               return(<div className="col-md-4" key={galleryImageIndex}>
                                                    <Link to={galleryImage}>
                                                        <img src={galleryImage} alt={galleryImage} />
                                                    </Link>
                                                </div>)
                                            })}
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                </div>
                            </section>
                        </main>
                    }
                })
            }
        }

        return galleryPageHtml;
    }
}
export default Gallery