import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';

/* Gallery Section */

const gallery = [
    {
        gal_img_id : "1",
        gallery_name : "MAIZE",
        gal_img : "assets/images/5.jpg",
    },
    {
        gal_img_id : "2",
        gallery_name : "SESAME",
        gal_img : "assets/images/6.jpg",
    },
    {
        gal_img_id : "3",
        gallery_name : "SOYA BEAN",
        gal_img : "assets/images/7.jpg",
    },
    {
        gal_img_id : "4",
        gallery_name : "SOYA BEAN",
        gal_img : "assets/images/8.jpg",
    },
    {
        gal_img_id : "5",
        gallery_name : "SOYA BEAN",
        gal_img : "assets/images/9.jpg",
    },
    {
        gal_img_id : "6",
        gallery_name : "SOYA BEAN",
        gal_img : "assets/images/10.jpg",
    },
];

const gallery_content = gallery.map((gallery) =>
    <div className="col-md-4" key={gallery.gal_img_id}>
        <Link to={gallery.gal_img}>
            <img src={gallery.gal_img} alt={gallery.gallery_name} />
        </Link>
    </div>
);

class Events extends Component{
    render(){

        return(
            <main id="main">
                {/* <!-- Hero --> */}
                <section id="slider" className="hero p-0 odd featured">
                    <div className="swiper-container no-slider slider-h-75">
                        <div className="swiper-wrapper">
                            {/* <!-- Item 1 --> */}
                            <div className="swiper-slide slide-center">
                                <img src="assets/images/about_bg.png" className="full-image" data-mask="70" alt="" />
                                <div className="slide-content row text-center">
                                    <div className="col-12 mx-auto inner">
                                        <h1 className="title effect-static-text">Rings the Bell for Financial</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item"><Link to="/events">Events</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Rings the Bell for Financial</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" className="single">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <h2 className="featured mt-0 ml-0">Content</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                <img src="assets/images/thank-you.png" alt="" />

                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                <div className="pt-2"></div>
                                <h2 className="featured mt-5 mb-5 ml-0">Gallery</h2>

                                <SimpleReactLightbox>
                                    <SRLWrapper>
                                        <div className="row gallery_img">
                                            {gallery_content}
                                        </div>
                                    </SRLWrapper>
                                </SimpleReactLightbox>

                            </div>
                        </div>
                    </div>
                </section>

            </main>
        )
    }
}
export default Events