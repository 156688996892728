import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class Contact extends Component{
    render(){
        return(
            <main id="main">
                {/* <!-- Hero --> */}
                <section id="slider" className="hero p-0 odd featured">
                    <div className="swiper-container no-slider slider-h-75">
                        <div className="swiper-wrapper">
                            {/* <!-- Item 1 --> */}
                            <div className="swiper-slide slide-center">
                                <img src="assets/images/thank-you.png" className="full-image" data-mask="70" />
                                <div className="slide-content row text-center">
                                    <div className="col-md-12 mx-auto inner">
                                        <h1 className="title effect-static-text">Investment</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Investment</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Buy own operate --> */}
                <section className="section-3 offers">
                    <div className="container">
                        <div className="row intro">
                            <div className="col-md-12 col-md-12 align-self-center text-center text-md-left">
                                <h3 className="featured text-center">Buy own operate (BOO)</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center items investment_icon">
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/warehouse.png" />
                                    <h4>Warehouse</h4>
                                    <p>Whether you are a business that deals in large quantities of agro raw materials for industrial use, or a firm that needs to store huge quantities of...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/warehouses.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/city-hall.png" />
                                    <h4>Banking hall</h4>
                                    <p>The investment here entails that the Financial institution/financial services provider pays for the plot, builds, operates and owns the facility within...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/banking%20hall.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/gas.png" />
                                    <h4>Fuel Station</h4>
                                    <p>The infrastructure investment here entails the investor pays for the Filling station plot, builds, operates and owns the facility within the Market...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/filling%20station.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/restaurant.png" />
                                    <h4>Restaurant</h4>
                                    <p>The infrastructure investment here entails that the Food Service provider buys the plot, builds and operates within the designated plots for Food...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/restaurant.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Build own operate model --> */}
                <section className="section-1 offers">
                    <div className="container">
                        <div className="row intro">
                            <div className="col-md-12 col-md-12 align-self-center text-center text-md-left">
                                <h3 className="featured text-center">Build own operate model (BOOM)</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center items investment_icon">
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/ticket-office.png" />
                                    <h4>Single Shops And Offices</h4>
                                    <p>The investor buys already completed structure and operates within the Market. The shop/office serves as contact point/liaison within the market...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/single-shops-offices.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/luxury.png" />
                                    <h4>Luxury Shops And Offices</h4>
                                    <p>The investor buys already completed structure and operates within the Market. The shop/office serves as contact point/liaison within the market...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/banking%20hall.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Strategic partnership --> */}
                <section className="section-3 offers">
                    <div className="container">
                        <div className="row intro">
                            <div className="col-md-12 col-md-12 align-self-center text-center text-md-left">
                                <h3 className="featured text-center">Strategic partnership (SPM)</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center items investment_icon">
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/resort.png" />
                                    <h4>HOTEL</h4>
                                    <p>This investment model involves a strategic partnership (JV) between the investor and Gezawa Commodity Market & Exchange to provide a Standard Hotel...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/hotel.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/laboratory.png" />
                                    <h4>LABORATORY</h4>
                                    <p>This investment model involves a strategic partnership between the Laboratory Service Providers and Gezawa Commodity Market & Exchange, to provide standard...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/laboratory.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/air-freight.png" />
                                    <h4>LOGISTICS</h4>
                                    <p>This investment model involves a strategic partnership between Logistics Service Providers and Gezawa Commodity Market & Exchange to provide efficient...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/logistics.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/import.png" />
                                    <h4>FREIGHT FORWARDERS</h4>
                                    <p>This investment model involves a Strategic Partnership between Licensed Freight Forwarding Service Providers and Gezawa Commodity Market...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/freight%20forwarders.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/free-shipping.png" />
                                    <h4>SHIPPING LINES</h4>
                                    <p>This investment model involves a Strategic Partnership between Licensed Shipping companies and Gezawa Commodity Market & Exchange for the...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/shipping%20lines.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Build operate transfer --> */}
                <section className="section-1 offers">
                    <div className="container">
                        <div className="row intro">
                            <div className="col-md-12 col-md-12 align-self-center text-center text-md-left">
                                <h3 className="featured text-center">Build operate transfer(BOT)</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center items investment_icon">
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/parking-area.png" />
                                    <h4>CAR AND TRAILER PARK</h4>
                                    <p>The investor builds the Car/Trailer Park, operates it for agreed number of years and then transfers ownership to the market at the expiration of the agreed term..</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/car%20park.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/car-wash.png" />
                                    <h4>CAR WASH</h4>
                                    <p>The investor Constructs the Car wash, operates it for agreed number of years and then transfers ownership to the market at the expiration of the agreed term...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/car%20wash.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/workshop.png" />
                                    <h4>AUTOMOBILE WORKSHOP</h4>
                                    <p>The investor builds the Automobile Workshop, operates it for agreed number of years and then transfers ownership to the Market. The market provides land...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/automobile%20workshop.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 item">
                                <div className="card featured">
                                    <img src="assets/images/icons/public.png" />
                                    <h4>PUBLIC CONVENIENCE</h4>
                                    <p>The investor builds the Public Convenience, operates it for agreed number of years and then transfers ownership to the Market. The market provides land...</p>
                                    <a className="team_mem_link pb-3" href="https://gezawacommoditymarket.com.ng/Public%20Convenience.html" target="_blank">Learn More &nbsp;<i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}
export default Contact