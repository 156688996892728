import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from "axios";
import parse from 'html-react-parser';

function Events() {

    const [eventCategoriesData, setEventCategories] = useState([]);
    const [eventsData, setEvents] = useState([]);
    const fetchData = async () => {
        var eventCategoriesUrl = global.config.api_url + "get-event-categories";
        const result = await axios.post(eventCategoriesUrl);
        if (result.status === 200) {
            if (typeof result.data.data != "undefined") {
                var eventCategoriesResultData = result.data.data;
                setEventCategories(eventCategoriesResultData);
            }
        }
        
        var eventUrl = global.config.api_url + "get-event";
        const eventResult = await axios.post(eventUrl);
        if (eventResult.status === 200) {
            if (typeof eventResult.data.data != "undefined") {
                var eventResultData = eventResult.data.data;
                setEvents(eventResultData);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    console.log(eventsData);
    return(<main id="main">
        <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider slider-h-75">
                <div className="swiper-wrapper">
                    <div className="swiper-slide slide-center">
                        <img src="assets/images/about_bg.png" className="full-image" data-mask="70" alt="" />
                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">
                                <h1 className="title effect-static-text">Events</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Events</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <section id="event_list">
            <div className="overflow-holder">
                <div className="container">
                    <div className="items tz_main_event_list">
                        <Tabs>
                            <TabList>
                                <Tab>All</Tab>
                                {eventCategoriesData.map((eventCategory)=>{
                                return(<Tab key={eventCategory.id} >{eventCategory.name}</Tab>);
                                })}
                            </TabList>
    
                            <TabPanel>
                            {eventsData.map((event)=>{
                               return(<div className="row card text-center" key={event.id}>
                                        <Link className="team_mem_link" to={'/event/'+event.slug} >
                                        <img src={event.images.slice(0, 1)} alt="Adams Baker" className="event_list_img" /></Link>
                                            <div className="tzb_cont_desc">
                                                <h4>{event.title}</h4>
                                                <p>{parse(event.description)}</p>
                                                <Link className="team_mem_link" to={'/event/'+event.slug} >Read More &nbsp;<i className="fa fa-angle-double-right"></i></Link>
                                            </div>
                                        </div>)
                            })}
                            </TabPanel>
                            
                            {eventCategoriesData.map((eventCategory)=>{
                               return(<TabPanel key={eventCategory.id} >
                              {eventsData.map((event)=>{
                               return((event.event_categories_id === eventCategory.id)?<div className="row card text-center" key={event.id}>
                                        <Link className="team_mem_link" to={'/event/'+event.slug} >
                                        <img src={event.images.slice(0, 1)} alt="Adams Baker" className="event_list_img" /></Link>
                                            <div className="tzb_cont_desc">
                                                <h4>{event.title}</h4>
                                                <p>{parse(event.description)}</p>
                                                <Link className="team_mem_link" to={'/event/'+event.slug} >Read More &nbsp;<i className="fa fa-angle-double-right"></i></Link>
                                            </div>
                                        </div>:"");
                                })}
                                       </TabPanel>); 
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    </main>)

}
export default Events